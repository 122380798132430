module.exports = {
  it: 'Italiano',
  ar: 'العربية',
  de: 'Deutsch',
  fr: 'Français',
  'pt-pt': 'Português (Portugal)',
  'pt-br': 'Português (Brasil)',
  'es-es': 'Español (España)',
  es: 'Español',
  en: 'English (US)',
}
